import { Translate } from 'next-translate';

import { IconOption } from '@common/atoms/Icon';
import { SportDataPlayer } from '@common/clients/api';
import { Alignment, Columns } from '@web/molecules/StatsTable';

export type PlayerColumns = ReturnType<typeof getPlayerColumns>;
export type StaffColumns = ReturnType<typeof getStaffColumns>;

export const isPlayer = <T extends SportDataPlayer>(person: T): person is T => person.type === 'player';

export const getPlayerColumns = (title: string = '', __stats: Translate): Columns => ({
    rank: {
        headerColSpan: 2,
        class: 'minimum-width',
        title,
    },
    name: {
        alignment: Alignment.Left,
    },
    appearances: {
        icon: IconOption.basePlace,
        alignment: Alignment.Centered,
        description: __stats`meta.appearances`,
    },
    assists: {
        icon: IconOption.assist,
        alignment: Alignment.Centered,
        description: __stats`meta.assists`,
        hideOnMobile: true,
    },
    goals: {
        icon: IconOption.football,
        alignment: Alignment.Centered,
        description: __stats`meta.goals`,
    },
    redCards: {
        icon: IconOption.redCard,
        alignment: Alignment.Centered,
        description: __stats`meta.redCards`,
        hideOnMobile: true,
    },
    yellowCards: {
        icon: IconOption.yellowCard,
        alignment: Alignment.Centered,
        description: __stats`meta.yellowCards`,
        hideOnMobile: true,
    },
});

export const getStaffColumns = (title: string = '', __stats: Translate): Columns => ({
    rank: {
        headerColSpan: 2,
        class: 'minimum-width',
        title,
    },
    name: {
        alignment: Alignment.Left,
    },
});
