import { FC, Fragment, useRef, useState } from 'react';
import { gsap, Power1 } from 'gsap';
import useTranslation from 'next-translate/useTranslation';

import { Match, ModuleScheduleItem, ModuleScheduleSection } from '@common/clients/api';
import { formatOddsGroupsUrls } from '@common/utils/betting';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';
import { BettingDisclaimer } from '@web/atoms/BettingDisclaimer';
import { Route } from '@web/routing';

import { CompetitionElement } from './CompetitionElement';
import { DateElement } from './DateElement';
import { GroupByElement } from './GroupByElement';
import { MatchElement } from './MatchElement';

import styles from './MatchList.module.scss';

export type Props = {
    moduleScheduleItem?: ModuleScheduleItem;
    matches: Match[];
    emptyState?: any;
    groupBy?: 'competition' | 'default' | 'date';
    showOdds?: boolean;
    maxShown?: number;
    trackerName?: ModuleScheduleSection;
    route?: Route;
};

const generateDateGroupBy = (match: Match) => {
    return DateTimeUtil.format(match.kickOff, Format.DATE_NUMERIC);
};

const generateDefaultGroupBy = (match: Match) => {
    return `${generateDateGroupBy(match)} ${match.stageName || ''}`;
};

export const MatchList: FC<Props> = ({
    matches,
    groupBy,
    maxShown: intialMaxShown,
    moduleScheduleItem,
    showOdds,
    trackerName,
    route,
}) => {
    const __actions = useTranslation('actions').t;
    const ref = useRef<HTMLUListElement>(null);
    const buttonRef = useRef<HTMLLIElement>(null);

    const [maxShown, setMaxShown] = useState<number | undefined>(intialMaxShown);

    let lastGroupKey: string | undefined;
    let isOdd: boolean = false;
    let counter: number = 0;
    let showDisclaimer: boolean = false;
    let hasOdds: boolean = false;

    const onExpand = () => {
        if (maxShown) {
            let nextCap = maxShown + maxShown;
            let maxIsReached = matches.length - nextCap <= 5;
            if (maxIsReached) {
                nextCap = matches.length;
                maxIsReached = true;
            }

            if (ref.current && buttonRef.current && maxShown) {
                gsap.fromTo(
                    ref.current,
                    {
                        height:
                            ref.current.offsetHeight - (maxIsReached ? buttonRef.current.offsetHeight : 0),
                        overflow: 'hidden',
                    },
                    {
                        height: 'auto',
                        duration: (nextCap - maxShown) * 0.1,
                        ease: Power1.easeInOut,
                    },
                );
            }
            setMaxShown(nextCap);
        }
    };

    return (
        <ul className={styles.MatchList} ref={ref}>
            {matches.map((_match) => {
                const match = { ..._match };
                if (maxShown && counter >= maxShown) return;

                const lastHasOdds = hasOdds;
                hasOdds = Boolean(moduleScheduleItem && match.odds?.length);

                if (match.odds && moduleScheduleItem) {
                    match.odds = formatOddsGroupsUrls(moduleScheduleItem, match.odds);
                }

                let group: any = null;
                let date: any;
                let hasIconBar = true;

                const lastIsOdd = isOdd;
                if (
                    groupBy === 'competition' &&
                    match.competition?.id !== lastGroupKey &&
                    match.competition
                ) {
                    group = (
                        <CompetitionElement key={match.competition?.id} competition={match.competition} />
                    );
                    lastGroupKey = match.competition?.id;
                    isOdd = false;
                } else if (groupBy === 'date' && (date = generateDefaultGroupBy(match)) !== lastGroupKey) {
                    group = <DateElement key={date} match={match} />;
                    lastGroupKey = date;
                    isOdd = false;
                    hasIconBar = counter === 0;
                } else if (groupBy === 'default' && (date = generateDateGroupBy(match)) !== lastGroupKey) {
                    group = <GroupByElement key={date} match={match} />;
                    lastGroupKey = date;
                    isOdd = false;
                    hasIconBar = counter === 0;
                }

                isOdd = !isOdd;
                counter++;

                const nextMatch = matches[counter];
                const lastShowDisclaimer: boolean = showDisclaimer;
                showDisclaimer = hasOdds && (!nextMatch || !nextMatch.odds || !nextMatch.odds.length);

                return (
                    <Fragment key={match.id}>
                        {group && (
                            <>
                                {lastHasOdds && !lastShowDisclaimer && (
                                    <li
                                        className={`${styles['disclaimer-bar']} ${
                                            lastIsOdd ? styles.odd : ''
                                        }`}
                                    >
                                        <BettingDisclaimer showEighteenPlusLogo />
                                    </li>
                                )}
                                {group}
                                {hasIconBar && (
                                    <li className={styles['icon-bar']} key={match.id + '-icons'}>
                                        <span className="f-icon timer-icon" />
                                        <span className="f-icon shield-solid-icon" />
                                    </li>
                                )}
                            </>
                        )}
                        <MatchElement
                            key={match.id}
                            match={match}
                            isOdd={isOdd}
                            showOdds={showOdds}
                            trackerName={trackerName}
                            route={route}
                        />
                        {showDisclaimer && (
                            <li className={`${styles['disclaimer-bar']} ${isOdd ? styles.odd : ''}`}>
                                <BettingDisclaimer showEighteenPlusLogo />
                            </li>
                        )}
                    </Fragment>
                );
            })}
            {maxShown && maxShown < matches.length ? (
                <li style={{ textAlign: 'center' }} ref={buttonRef}>
                    <button onClick={onExpand}>{__actions`expand`}</button>
                </li>
            ) : null}
        </ul>
    );
};
