import { FC, useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import {
    Match,
    ModuleScheduleSection,
    ModuleScheduleSection as ModuleSection,
    News,
    PlatformID,
    SportDataPlayer,
    SportDataTeam,
    SportDataTeamSelection,
} from '@common/clients/api';
import { ModulesBySection } from '@web/handlers/fetchActiveModules';
import { fetchNews } from '@web/handlers/fetchNews';
import { Ad, AdPlacement } from '@web/molecules/Ad';
import { ComponentHead } from '@web/molecules/ComponentHead';
import { MatchList } from '@web/molecules/MatchList';
import { MatchTicker } from '@web/molecules/MatchTicker';
import { NewsList } from '@web/molecules/NewsList';
import { NotificationBarHolder } from '@web/molecules/NotificationBar';
import { TabbedContent } from '@web/molecules/TabbedContent';
import { TagIntroBlock, TagIntroBlockProps, TagIntroBlockType } from '@web/molecules/TagIntroBlock';
import { TagOutroBlock } from '@web/molecules/TagOutroBlock/TagOutroBlock';
import { MetaHead } from '@web/organisms/MetaHead';
import { Sidebar } from '@web/organisms/Sidebar';
import { Route } from '@web/routing';
import { Props as TagPageProps, State as TagPageState } from '@web/templates/TagDetail';

import { SelectionTable } from './organisms/SelectionTable';

export interface State extends TagPageState {
    isLoading: boolean;
    competitionSlug: string;
    optaID?: string;
    fixtures: Match[];
    results: Match[];
    team?: SportDataTeam;
    selection?: SportDataTeamSelection;
}

export interface Props extends TagPageProps {
    modulesBySection?: ModulesBySection;
    state: State;
    showOdds: boolean;
}

export const ClubDetail: FC<Props> = ({ state: initialState, contextData, modulesBySection, showOdds }) => {
    const __stats = useTranslation('stats').t;
    const __meta = useTranslation('meta').t;
    const [state, setState] = useState(initialState);

    const oddsMatchesModule = modulesBySection?.[ModuleSection.ODDS_MATCHES]?.[0];
    const oddsMatchTickerModule = modulesBySection?.[ModuleSection.ODDS_MATCH_TICKER]?.[0];
    const matchTabs = [];
    if (state.fixtures.length) {
        matchTabs.push({
            title: __stats`title.fixtures`,
            content: (
                <MatchList
                    moduleScheduleItem={oddsMatchesModule}
                    matches={state.fixtures}
                    groupBy="default"
                    showOdds={showOdds}
                    maxShown={4}
                    trackerName={ModuleScheduleSection.ODDS_MATCHES}
                    route={Route.Club}
                />
            ),
        });
    }
    if (state.results.length) {
        matchTabs.push({
            title: __stats`title.results`,
            content: (
                <MatchList
                    moduleScheduleItem={oddsMatchesModule}
                    matches={state.results}
                    groupBy="default"
                    showOdds={showOdds}
                    maxShown={4}
                    trackerName={ModuleScheduleSection.ODDS_MATCHES}
                    route={Route.Club}
                />
            ),
        });
    }

    const onReadMoreClick = async (): Promise<void> => {
        const newState: State = {
            ...state,
            currentPage: state.currentPage + 1,
        };

        const data = await fetchNews({
            contextData,
            currentPage: newState.currentPage,
            isClientSide: true,
            tagID: state.tag?.tagID,
        });
        data?.data?.forEach((item: News) => {
            state.newsItems?.push(item);
        });

        newState.newsItems = state.newsItems;
        newState.pagination = data?.pagination;

        setState(newState);
    };

    const tagIntroBlockData: TagIntroBlockProps = {
        tag: state.tag,
        type: TagIntroBlockType.COMMON,
        superTitle: __stats`title.club`,
        title: state.tag?.title || state.team?.name || '',
        description: state.tag?.tagIntro || state.tag?.tagMetaDescription || '',
        thumbnail: state.tag?.thumbnail || state.team?.tag?.thumbnail || '',
        backgroundImage: state.tag?.background || state.team?.tag?.background || '',
        hasTagOutro: Boolean(state.tag?.tagIntro) && Boolean(state.tag?.tagDescription),
        hasNoSpaceBelow: matchTabs.length > 0,
    };

    const tagOutro = state.tag?.tagDescription || '';

    const { selection } = state;
    const hasPlayerSelection = useMemo(
        () =>
            [selection?.attackers, selection?.defenders].every(
                (players: SportDataPlayer[] = []) => players?.length > 0,
            ),
        [selection],
    );

    const metaTitle = useMemo(
        () =>
            state.tag?.tagMetaTitle ||
            __meta('club.title').replace(/{{theClub}}/g, state.tag?.title || state.team?.name || ''),
        [state, __meta],
    );

    const metaDescription = useMemo(
        () =>
            state.tag?.tagMetaDescription ||
            __meta('club.description').replace(/{{theClub}}/g, state.tag?.title || state.team?.name || ''),
        [state, __meta],
    );

    const isPrimary = contextData.platform.id === PlatformID.VI;

    return (
        <>
            <MatchTicker moduleScheduleItem={oddsMatchTickerModule} />
            <MetaHead
                title={metaTitle}
                description={metaDescription}
                image={state.tag?.thumbnail}
                parents={[Route.ClubOverview]}
            />
            <div className="main-content">
                <>
                    <TagIntroBlock {...tagIntroBlockData} />
                    <Ad placement={AdPlacement.NON_HOME_AFTER_SECTION_1} />
                    {matchTabs.length > 0 ? (
                        <>
                            <TabbedContent
                                title={state.team?.name ?? ''}
                                subtitle={__stats`title.matches`}
                                isPrimary={isPrimary}
                                tabs={matchTabs}
                            />
                            <Ad placement={AdPlacement.NON_HOME_AFTER_SECTION_2} />
                        </>
                    ) : null}
                    {hasPlayerSelection ? (
                        <>
                            <ComponentHead
                                title={state.team?.name ?? ''}
                                subTitle={__stats`title.selection`}
                            />
                            <SelectionTable
                                items={selection?.goalkeepers}
                                title={__stats`title.goalkeepers`}
                            />
                            <SelectionTable items={selection?.defenders} title={__stats`title.defenders`} />
                            <SelectionTable
                                items={selection?.midfielders}
                                title={__stats`title.midfielders`}
                            />
                            <SelectionTable items={selection?.attackers} title={__stats`title.attackers`} />
                            <SelectionTable items={selection?.coaches} title={__stats`title.staff`} />
                            {!matchTabs.length ? (
                                <Ad placement={AdPlacement.NON_HOME_AFTER_SECTION_2} />
                            ) : null}
                        </>
                    ) : null}
                    <NewsList
                        items={state.newsItems}
                        skipDivider={true}
                        pagination={state.pagination}
                        tag={state.tag}
                        onReadMoreClick={onReadMoreClick}
                        showIconTabs={true}
                        promoItems={state.promoItems}
                    />
                    {tagIntroBlockData?.hasTagOutro ? <TagOutroBlock tagOutro={tagOutro} /> : null}
                </>
            </div>
            <Sidebar />
            <NotificationBarHolder />
        </>
    );
};
